import { AppSpinner } from '@one-snap/sparkles';
import { useAuth } from 'auth/useAuth';
import { LoginForm } from 'components/forms/login-form/login-form';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { appConfig } from 'utilities/constants/app-config';
import { LoginLayout } from './login-layout';
import { LoginPanel } from './login-panel';
import { useAppSelector } from 'state/hooks';

export const LoginComponent = () => {
  const t = useTranslations();
  const email = useAppSelector((state) => state.userInputs.values.email);
  const { loginWithRedirect, isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated && !appConfig.isDetermineEnable) {
      loginWithRedirect({ authorizationParams: { login_hint: email } });
    }
  }, [isAuthenticated, loginWithRedirect, email]);

  return appConfig.isDetermineEnable ? (
    <LoginLayout>
      <LoginPanel>
        <h3 className="text-2xl font-semibold text-gray-600 mt-4">
          {t('login.subTitle')}
        </h3>
        <h5 className="text-sm text-gray-500">{t('login.codeInfo')}</h5>
        <div className="flex justify-between w-full mb-5">
          <LoginForm />
        </div>
      </LoginPanel>
    </LoginLayout>
  ) : (
    !isAuthenticated && <AppSpinner />
  );
};
