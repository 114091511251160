import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';
import { useMemo } from 'react';
import {
  PaymentInputs,
  persistInputs,
  resetInputs
} from './slices/payment-inputs';
import { InputValues, persistUserInputs } from './slices/user-inputs';
import { PaymentOutputs, persistOutputs, resetOutputs } from './slices/payment-outputs';
import { resetModules } from 'jest.preset';

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const usePaymentsFlow = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      persistInputs: (inputs: PaymentInputs) => dispatch(persistInputs(inputs)),
      persistOutputs: (outputs: PaymentOutputs) => dispatch(persistOutputs(outputs)),
      resetOutputs: () => dispatch(resetOutputs()),
      resetInputs: () => dispatch(resetInputs())
    }),
    [dispatch]
  );
};

export const useUserInput = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      persistUserInputs: (inputs: InputValues) =>
        dispatch(persistUserInputs(inputs))
    }),
    [dispatch]
  );
};

export const usePaymentOutputs = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      persistOutputs: (outputs: PaymentOutputs) =>
        dispatch(persistOutputs(outputs))
    }),
    [dispatch]
  );
};
