import { createCommandHook } from '@one-snap/core';
import { useCallback } from 'react';
import { useSendOTP } from '../hooks/useSendOTP';
import { ErrorType } from './types';

const getErrors = (response) => {
  const errors = [];
  if (!response?.success) {
    switch (response?.status) {
      case 429:
        errors.push(ErrorType.OTP_SEND_ACCOUNT_BLOCKED);
        break;
      case 530:
        errors.push(ErrorType.IP_ADDRESS_BLOCKED);
        break;
      default:
        errors.push(ErrorType.SMS_OTP_ERROR);
        break;
    }
  }
  return errors;
};

export const useSendOTPCommand = createCommandHook(() => {
  const { sendOtp, sendOtpLoading } = useSendOTP();

  const execute = useCallback(
    async (params: { email: string }) => {
      const response = await sendOtp(params.email);
      let errors = [];
      errors = getErrors(response);
      return {
        errors,
        data: response.data
      };
    },
    [sendOtp]
  );

  return {
    execute,
    executing: sendOtpLoading
  };
});
