import { useCall, useFetchWithErrorTracking } from '@one-snap/core';

export function useSendOTP() {
  const { fetchWithErrorTracking } = useFetchWithErrorTracking();
  const { call, data, loading, error } = useCall<unknown>(
    async (email: string) =>
      await fetchWithErrorTracking(
        `${process.env.NEXT_PUBLIC_CONSUMER_API}/v1/customer/otp/send`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Referrer-Policy': 'no-referrer-when-downgrade'
          },
          body: JSON.stringify({ customerIdentifier: email }),
          handleErrorTrackingSeverity(httpStatus) {
            if (httpStatus === 400) {
              return 'warn';
            }
            return 'error';
          }
        }
      )
  );

  return {
    sendOtp: call,
    sendOtpResponse: data,
    sendOtpLoading: loading,
    sendOtpError: error
  };
}
