export enum ErrorType {
  PASSWORD_RESET = 'passwordResetError',
  SMS_OTP_ERROR = 'SMS_OTP_ERROR',
  SMS_OTP_CHECK_ERROR = 'SMS_OTP_CHECK_ERROR',
  SMS_INVALID_OTP_ERROR = 'SMS_INVALID_OTP_ERROR',
  SMS_OTP_MAX_ATTEMPTS_EXCEEDED_ERROR = 'SMS_OTP_MAX_ATTEMPTS_EXCEEDED_ERROR',
  OTP_CHECK_ACCOUNT_BLOCKED = 'OTP_CHECK_ACCOUNT_BLOCKED_ERROR',
  VERIFICATION_EXPIRED = 'VERIFICATION_EXPIRED',
  IP_ADDRESS_BLOCKED = 'IP_ADDRESS_BLOCKED',
  OTP_AUTH_TOKEN_NOT_RECEIVED = 'OTP_AUTH_TOKEN_NOT_RECEIVED_ERROR',
  DETERMINE_ERROR = 'DETERMINE_ERROR',
  VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR',
  OTP_SEND_ACCOUNT_BLOCKED = 'OTP_SEND_ACCOUNT_BLOCKED_ERROR',
}

export interface PlatformResponse<T> {
  data: T;
  status: number;
  success: boolean;
  error: {
    type: string;
    message: string;
    code: string;
  }[];
}
