import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from 'auth/useAuth';
import { LoginComponent } from 'components/features/login/login';
import { GetStaticPropsContext } from 'next';
import { AppSpinner } from '@one-snap/sparkles';
import { routes } from 'utilities/routing';
import { appConfig } from 'utilities/constants/app-config';

export default function Login() {
  const { isAuthenticated, isLoading } = useAuth();
  const { push } = useRouter();
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      push(routes.dashboard.home());
    }
  }, [isAuthenticated, isLoading, push]);

  if (isLoading) return <AppSpinner />;

  return (
    <>
      {isAuthenticated ? <AppSpinner /> : <LoginComponent />}
      {!appConfig.isDetermineEnable && <AppSpinner />}
    </>
  );
}

export async function getStaticProps({
  locale
}: GetStaticPropsContext<any, any>) {
  return {
    props: {
      noLayout: true,
      messages: {
        login: { ...require(`../i18n/pages/login/${locale}.json`) },
        shared: { ...require(`../i18n/shared/${locale}.json`) }
      }
    }
  };
}
