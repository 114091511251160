import Link from 'next/link';
import { routes } from 'utilities/routing';

export const HelpFooter = () => {
  return (
    <div className="flex max-w-full md:rounded-bl-lg md:rounded-br-lg snap-background-color-grey-300 py-6 px-10">
      <div>
        <span className="text-sm">Need Help? Live Chat or Visit our</span>
        <Link
          className="text-sm text-center text-blue-900 hover:text-blue-700 underline ml-1"
          href={routes.dashboard.help()}
        >
          Help Center
        </Link>
      </div>
    </div>
  );
};
