import * as yup from 'yup';

const REQUIRED = 'shared.forms.validation.required';
const INVALID_EMAIL = 'shared.forms.validation.invalidEmail';
export interface FormSchemaData {
  email: string;
}

export const formSchema: yup.SchemaOf<FormSchemaData> = yup.object().shape({
  email: yup.string().required(REQUIRED).email(INVALID_EMAIL)
});
