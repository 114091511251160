import { ReactNode } from 'react';

export interface LoginLayoutProps {
  children: ReactNode;
  legaleseFooterDisclaimer?: ReactNode;
  showBackButton?: boolean;
  showApplicationId?: boolean;
}

export function LoginLayout({ children }: LoginLayoutProps) {
  return (
    <div className="snap-background-color-dark-blue min-h-screen flex content-center flex-wrap justify-center">
      {/* <div className="flex-grow"></div> */}
      {children}
    </div>
  );
}
