import { SnapLogo } from '@one-snap/next';
import { HelpFooter } from 'components/ui/help-footer';
import { ReactNode } from 'react';

export interface LoginPanelProps {
  children: ReactNode;
}

export function LoginPanel({ children }: LoginPanelProps) {
  return (
    <div className="flex flex-col md:flex-row md:items-center md:justify-center md:h-screen">
      <div className="snap-background-color-white lg:rounded-lg shadow-lg flex flex-col">
        <div className="flex flex-col py-8 px-10 lg:mx-2">
          <div className="mt-8 mb-8 self-center">
            <SnapLogo size="lg" />
          </div>
          <div className="flex-grow">{children}</div>
          <div className="mt-auto"></div>
        </div>
        <HelpFooter />
      </div>
    </div>
  );
}
